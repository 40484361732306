import React, { Component } from 'react';
import { Typography, Box, Link } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook'; // Import the FacebookIcon
import Header from './Header';
import Footer from './Footer';

export default class ContactForm extends Component {
  render() {
    return (
      <>
        <Header />
        <Box className="contacts" sx={{ padding: 2, marginTop: '4rem' }}>
          <Typography variant="h5" gutterBottom>
            Please get in touch with us on:
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            <EmailIcon sx={{ marginRight: 0.5, color: '' }} />
            <Link href="mailto:info@aquasparkpools.co.za" underline="hover">
              info@aquasparkpools.co.za
            </Link>
          </Typography>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <WhatsAppIcon sx={{ marginRight: 0.5, color: '#389602' }} />
            <Link href="https://wa.me/27645424371" underline="hover">
              WhatsApp
            </Link>
          </Typography>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <TelegramIcon sx={{ marginRight: 0.5, color: '#02929c' }} />
            <Link href="https://t.me/27645424371" underline="hover">
              Telegram
            </Link>
          </Typography>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <PhoneIcon sx={{ marginRight: 0.5, color: '#389602' }} />
            <Link href="tel:+27645424371" underline="hover">
              0645424371
            </Link>
          </Typography>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <FacebookIcon sx={{ marginRight: 0.5, color: '#3b5998' }} />
            <Link href="https://www.facebook.com/profile.php?id=61560871242922" underline="hover">
              Facebook
            </Link>
          </Typography>
        </Box>
        <Footer />
      </>
    );
  }
}

