import React, { Suspense, lazy } from 'react';

const ImageComponent = lazy(() => import('./ImageComponent'));

const ImageLoader = () => (
  <div>
    <Suspense fallback={<div>Loading...</div>}>
      <ImageComponent />
    </Suspense>
  </div>
);

export default ImageLoader;
