import React from 'react';
import Header from './Header';
import Services from './Services';
import ImageSlider from './ImageSlider';
import ImageLoader from '../images/ImageLoader';
import Footer from './Footer'

const Home = () => {
  return (
    <div>
      <nav>
        <ul>
            <li>
              <Header />
            </li>
            <li>
              <ImageSlider />
            </li>
            <li>
              <Services/>
            </li>
            <li>
              <ImageLoader />
            </li>
            <li>
              <Footer />
            </li>
        </ul>
      </nav>
    </div>
  );
};

export default Home;
