import React from "react";
import { Container, Typography, Box } from '@mui/material';
import ImageGallery from "../data/ImageGallery";
import CustomSlider from "./CustomSlider";
import "./styles.css";

export default function ImageSlider() {
  return (
    <div className="App">
      <CustomSlider className="custom-slider">
        {ImageGallery.map((image, index) => (
          <img key={index} src={image.imgURL} alt={image.imgAlt} />
        ))}
      </CustomSlider>
      <Container>
      <Box sx={{ mt: 1, marginLeft: '-40px'}}>
        <Typography variant="body1" sx={{ fontSize: '1.6rem', textAlign: 'center', fontFamily: 'Gentium' }}>
          Transform your backyard into a luxurious oasis with our custom-built swimming pools! Our expert team specializes in creating stunning, high-quality pools that perfectly match your lifestyle and aesthetic preferences. With decades of experience, we use only the finest materials and innovative construction techniques to ensure your pool is not only beautiful but also durable and safe. Imagine lounging by your very own pool, enjoying the perfect blend of relaxation and entertainment right at home. Whether you dream of a sleek, modern design or a tropical paradise, we bring your vision to life. Dive into excellence and make every day a vacation with our superior pool construction services!
        </Typography>
        </Box>
      </Container>
    </div>
  );
}




