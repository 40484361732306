import React, { Component } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import poolImage from '../images/pooll.jpg'; // Ensure the path is correct
import Header from './Header';
import Footer from './Footer';

export default class Services extends Component {
  render() {
    return (
      <>
      <Header />
      <Grid container spacing={2} sx={{ padding: 2, marginTop: '3rem' }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" sx={{ marginTop: 2, fontSize: '1.5rem' }}>
            Pool Construction:  
          </Typography>
          <ul>
            <li style={{ fontSize: '1.3rem' }}>We construct swimming pools from start to finish.</li>
          </ul>

          <Typography variant="h4" sx={{ marginTop: 2, fontSize: '1.5rem' }}>
            Pool maintenance:
          </Typography>
          <ul>
            <li style={{ fontSize: '1.3rem' }}>We skim the surface, brush the walls and vacuum the floor of the pool.</li>
          </ul>

          <Typography variant="h4" sx={{ marginTop: 2, fontSize: '1.5rem' }}>
            Pool renovation:
          </Typography>
          <ul>
            <li style={{ fontSize: '1.3rem' }}>Fibre glass and pool painting</li>
          </ul>

          <Typography variant="h4" sx={{ marginTop: 2, fontSize: '1.5rem' }}>
            Pool cleaning:
          </Typography>
          <ul>
            <li style={{ fontSize: '1.3rem' }}>...</li>
          </ul>

          <Typography variant="h4" sx={{ marginTop: 2, fontSize: '1.5rem' }}>
            Equipment:
          </Typography>
          <ul>
            <li style={{ fontSize: '1.3rem' }}>Installation, inspecting and maintaining pool equipment such as pumps, heaters, and lights.</li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={poolImage} alt="Service" style={{ width: '100%', height: 'auto' }} />
          </Box>
        </Grid>
        </Grid>
        <Footer />
      </> 
    );
  }
}
