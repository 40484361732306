import firstImage from '../images/double.jpg';
import secondImage from '../images/pool1.jpg';
import thirdImage from '../images/square.jpg';
import fourthImage from '../images/pentagon.jpg';
import fifthImage from '../images/pentag.jpg';

const images = [
  {
    imgURL: firstImage,
    imgAlt: "img-6"
  },
  {
    imgURL: secondImage,
    imgAlt: "img-2"
  },
  {
    imgURL: thirdImage,
    imgAlt: "img-3"
  },
  {
    imgURL: fourthImage,
    imgAlt: "img-4"
  },
  {
    imgURL: fifthImage,
    imgAlt: "img-5"
  }
];

export default images;
