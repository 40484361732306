import React, { Component } from 'react';
import { Typography, Box, Link } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';

export default class Footer extends Component {
  render() {
    return (
      <Box 
        className="footer" 
        sx={{ 
          padding: 0.2, // Reduced padding
          backgroundColor: '#0388adf2', 
          position: 'fixed', 
          bottom: 0, 
          right: 0,
          width: '100%', 
          display: 'flex',  
          justifyContent: 'space-around',  
        }}
      >
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
          <Link href="mailto:info@aquasparkpools.co.za" underline="hover">
            <EmailIcon sx={{ marginRight: 0.5, color: 'white' }} />
          </Link>
        </Typography>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
          <Link href="https://wa.me/27645424371" underline="hover">
            <WhatsAppIcon sx={{ marginRight: 0.5, color: '#389602' }} />
          </Link>
        </Typography>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
          <Link href="https://t.me/27645424371" underline="hover">
            <TelegramIcon sx={{ marginRight: 0.5, color: 'white' }} />
          </Link>
        </Typography>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>            
          <Link href="tel:+27645424371" underline="hover">
            <PhoneIcon sx={{ marginRight: 0.5, color: '#389602' }} />
          </Link>
        </Typography>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>  
          <Link href="https://www.facebook.com/profile.php?id=61560871242922" underline="hover">
            <FacebookIcon sx={{ marginRight: 0.5, color: '#3b5998' }} />
          </Link>
        </Typography>
      </Box>
    );
  }
}
