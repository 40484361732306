import './App.css';
import ContactForm from './components/ContactForm';
import Services from './components/Services';
import Home from './components/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contactform/" element={<ContactForm />} />
        <Route path="/services/" element={<Services />} />
        {/* Other routes */}
      </Routes>
    </Router>
  );
}

export default App;

